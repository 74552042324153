import React from 'react'

import { Typography, ThumbDown, ThumbUp } from '../../'

import './Rate.scss'

interface RateProps {
  text?: string
  onGood: () => void
  onBad: () => void
  className?: string
}

const Rate: React.FC<RateProps> = ({ text, onGood, onBad, className }) => {
  return (
    <div className={`rate ${className ? className : ''}`}>
      {text && <Typography variant="body">{text}</Typography>}
      <div>
        <span className="rate__bad">
          <ThumbDown onClick={onBad} />
          Bad
        </span>
        <span className="rate__good">
          <ThumbUp onClick={onGood} />
          Good
        </span>
      </div>
    </div>
  )
}

export default Rate
