import React from 'react'
import { useSelector } from 'react-redux'

import { selectActiveExerciseIndex, selectCurrentBookExerciseAmount } from 'store/services/Exercise/exerciseReducer'

import './ExerciseProgressBar.scss'

const ExerciseProgress: React.FC = () => {
  const currentIndex = useSelector(selectActiveExerciseIndex)
  const currentExercisesAmount = useSelector(selectCurrentBookExerciseAmount)
  const progressWidthInPercent = `${(currentIndex / currentExercisesAmount) * 100}%`

  return (
    <div className="exercise-progress-bar">
      <div className="exercise-progress-bar__progress" style={{ width: progressWidthInPercent }} />
    </div>
  )
}

export default ExerciseProgress
