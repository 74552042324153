import React, { useState, useCallback } from 'react'

import './EditProfile.scss'
import { useSelector, useDispatch } from 'react-redux'
import { selectUser, uploadAvatar, updateUser } from 'store/services/Auth/authReducer'
import { AvatarUpload, Typography, Button, ChevronDown } from '@astrid/components'
import EditProfileForm from 'components/EditProfileForm/EditProfileForm'
import AuthApi from 'store/services/Auth/api'

const EditProfile: React.FC = () => {
  const user = useSelector(selectUser)
  const dispatch = useDispatch()
  const [securityOpen, setSecurityOpen] = useState(false)

  const uploadUserAvatar = useCallback(
    async (file: File) => {
      const formData = new FormData()
      formData.append('avatar', file)
      await dispatch(uploadAvatar(formData))
    },
    [dispatch]
  )

  const removeAvatar = useCallback(async () => {
    await dispatch(updateUser({ avatar: null }))
  }, [dispatch])

  return (
    <div className="edit-profile">
      <div className="edit-profile--left">
        <AvatarUpload
          className="edit-profile__upload"
          file={user?.profile?.avatar}
          uploadImage={uploadUserAvatar}
          removeImage={removeAvatar}
        />
        <Button variant="outlined" size="small" className="edit-profile__logout" onClick={AuthApi.logout}>
          Logout
        </Button>
      </div>

      <div className="edit-profile__content">
        <Typography variant="h3">Account settings</Typography>
        <EditProfileForm user={user!} />
        <span onClick={() => setSecurityOpen((s) => !s)} className="edit-profile__security">
          <Typography variant="h4">Security</Typography>
          <ChevronDown style={{ transform: securityOpen ? 'rotate(180deg)' : 'none' }} />
        </span>
        {securityOpen && (
          <span className="edit-profile__security-info">
            <Typography variant="body">To delete your account, please email Support@astrideducation.com</Typography>
          </span>
        )}
      </div>
    </div>
  )
}

export default EditProfile
