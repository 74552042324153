import api from './api'
import { ExerciseType } from '@astrid/components'

export const trackExerciseActivity = (exerciseId: string, exerciseType: ExerciseType, bookId?: string) =>
  api.post('/activity', {
    exerciseId,
    exerciseType,
    bookId
  })

export const exerciseScore = (score: number, type: ExerciseType, word?: string) =>
  api.post('/exercise-score', {
    score,
    type,
    word
  })
