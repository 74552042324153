import React, { useState, useMemo } from 'react'

import { Book } from '../../types'
import { Typography, Button, SkeletonLoader, BookContext } from '../../index'

import './BookCover.scss'

interface IProps {
  book: Book
  bookContext: BookContext
  onStart: () => void
  disableContinue?: boolean
}

const BookCover: React.FC<IProps> = ({ book, bookContext, onStart, disableContinue }) => {
  const [imgLoaded, setImgLoaded] = useState(false)

  return (
    <div className="book-cover">
      <Typography variant="h2" className="book-cover__title">
        {book.title}
      </Typography>
      <div className="book-cover__content">
        <SkeletonLoader display={!imgLoaded} className="book-cover__img-loader" />
        <img hidden={!imgLoaded} src={book.imageUrl} alt="Book Cover" onLoad={() => setImgLoaded(true)} />
        <ul className="book-cover__info">
          <li>
            <span>Author</span>
            {book.author}
          </li>
          <li>
            <span>Illustrator</span>
            {book.illustrator}
          </li>
          <li>
            <span>Publisher</span>
            {book.publisher}
          </li>
        </ul>
      </div>
      <Button
        disabled={disableContinue}
        label={bookContext === BookContext.listening ? 'Listen' : 'Start reading'}
        onClick={onStart}
      />
    </div>
  )
}

export default BookCover
