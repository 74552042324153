import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import { selectUser, setOnboardingCompleted } from 'store/services/Auth/authReducer'
import { getAllBooks } from 'store/services/Book/bookReducer'
import { getAllBooksProgress } from 'store/services/BookProgress/bookProgressReducer'
import { RootState } from 'store/types'
import { OnboardingBook, GA_event } from '@astrid/components'
import { sanaGetScore } from 'api/sanaApi'
import { routes } from 'routes/RouterStackComponent'
import { GAEventCategory, GAEventActions } from 'utils/constants'
import CreateProfile from 'views/CreateProfile/CreateProfile'
import VerifyEmail from 'views/VerifyEmail/VerifyEmail'
import { VerificationStatus } from 'store/services/Auth/types'

const onboardingBookWrapperStyling: React.CSSProperties = {
  flex: '1 0 auto',
  display: 'flex'
}

interface IState {
  loading: boolean
}

interface IProps extends RouteComponentProps, ConnectedProps<typeof connector> {
  children: React.ReactNode
}

class ProfileWall extends React.Component<IProps, IState> {
  readonly state: IState = {
    loading: true
  }

  async componentDidMount() {
    const { getAllBooks, getAllBooksProgress } = this.props

    if (this.props.user?.profile) {
      await getAllBooks()
      await getAllBooksProgress()
    }
    this.setState({ loading: false })
  }

  onOnboardingBookFinish = async () => {
    await this.props.setOnboardingCompleted()
    GA_event({
      category: GAEventCategory.USER,
      action: GAEventActions.FINISH_ONBOARDING,
      label: 'Onboarding book finish'
    })
    this.props.history.replace(routes.onboarding_finished)
  }

  render() {
    const { loading } = this.state
    const { user } = this.props

    if (loading) {
      return null
    } else if (!user?.profile) {
      return <CreateProfile />
    } else if (user.verificationStatus === VerificationStatus.NOT_VERIFIED) {
      return <VerifyEmail />
    } else if (user && !user?.onboardingCompleted) {
      return (
        <div style={onboardingBookWrapperStyling}>
          <OnboardingBook
            onMount={() => {
              GA_event({
                category: GAEventCategory.USER,
                action: GAEventActions.START_ONBOARDING,
                label: 'Onboarding book start'
              })
            }}
            country={user.profile?.country}
            getSanaScore={sanaGetScore}
            onFinish={this.onOnboardingBookFinish}
          />
        </div>
      )
    } else {
      return this.props.children
    }
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    user: selectUser(state)
  }
}

const connector = connect(mapStateToProps, {
  getAllBooks,
  getAllBooksProgress,
  setOnboardingCompleted
})

export default connector(withRouter(ProfileWall))
