import React from 'react'
import { connect, ConnectedProps } from 'react-redux'

import {
  selectAllExercises,
  setActiveExerciseIndex,
  setCurrentBookExercisesAmount
} from 'store/services/Exercise/exerciseReducer'
import { selectUserCountry } from 'store/services/Auth/authReducer'

import {
  ExerciseTransition,
  BuildingExerciseData,
  ExerciseType,
  Book,
  BookProgress,
  GA_event,
  GA_customDimensions,
  BookContext,
  GetSanaScoreOptions
} from '@astrid/components'
import { sanaGetScore } from 'api/sanaApi'
import { trackExerciseActivity, exerciseScore } from 'api/activityApi'
import { CONFIG } from 'config'
import { RootState } from 'store/types'
import { updateOneBookProgress } from 'store/services/BookProgress/bookProgressReducer'
import { GAEventCategory, GAEventActions } from 'utils/constants'

import './BookFactory.scss'

interface IBookFactoryProps extends ConnectedProps<typeof connector> {
  book: Book
  bookProgress: BookProgress
  bookContext: BookContext
  onCompleteBook: () => void
}

class BookFactory extends React.Component<IBookFactoryProps> {
  private startTimestamp: number = 0

  async componentDidMount() {
    const { book, bookProgress, bookContext } = this.props
    this.startTimestamp = Date.now()
    this.props.setCurrentBookExercisesAmount(book.exercises.length)

    if (bookContext === BookContext.reading) {
      bookProgress.isCompleted ? this.setIndex(0) : this.props.setActiveExerciseIndex(bookProgress.index)
    } else if (bookContext === BookContext.listening) {
      bookProgress.isCompletedListening
        ? this.setIndex(0)
        : this.props.setActiveExerciseIndex(bookProgress.indexListening)
    }
  }

  componentWillUnmount() {
    const { book } = this.props
    const timeSpentInBook = ~~((Date.now() - this.startTimestamp) / 1000)
    GA_customDimensions(book.title, timeSpentInBook, 1)
    GA_event({
      category: GAEventCategory.BOOK,
      action: GAEventActions.BOOK_EXIT,
      label: 'Exited the book'
    })
    GA_customDimensions()
  }

  setIndex = (newIndex: number) => {
    const { bookContext } = this.props
    this.props.updateOneBookProgress(
      this.props.bookProgress._id,
      bookContext === BookContext.reading
        ? {
            index: newIndex,
            isCompleted: false
          }
        : {
            indexListening: newIndex,
            isCompletedListening: false
          }
    )
    this.props.setActiveExerciseIndex(newIndex)
  }

  goPrev() {
    const { index, indexListening } = this.props.bookProgress
    const { exercises } = this.props.book
    const newIndex = (this.props.bookContext === BookContext.reading ? index : indexListening) - 1

    if (newIndex >= 0 && newIndex < exercises.length - 1) {
      this.setIndex(newIndex)
    }
  }

  goNext = async () => {
    const { bookProgress, bookContext } = this.props
    const currentIndex = bookContext === BookContext.reading ? bookProgress.index : bookProgress.indexListening
    const { exercises } = this.props.book
    const isLastBookExercise = currentIndex >= exercises.length
    if (isLastBookExercise) return

    this.setIndex(currentIndex + 1)
  }

  onNextExercise = (exercisePercentageScore?: number, skipDelay: boolean = false) => {
    const { bookProgress, bookContext } = this.props
    const currentIndex = bookContext === BookContext.reading ? bookProgress.index : bookProgress.indexListening

    const currentExercise = this.props.exercises[currentIndex]
    const { MultiChoiseQuestion, WordBuilder } = ExerciseType
    const { type } = currentExercise

    if ([MultiChoiseQuestion, WordBuilder].includes(type)) {
      exerciseScore(
        exercisePercentageScore!,
        type,
        type === WordBuilder ? (currentExercise.data as BuildingExerciseData).wordOrSentence : undefined
      )
    }

    setTimeout(
      () => {
        this.goNext()
      },
      skipDelay ? 0 : CONFIG.NEXT_EXERCISE_DELAY
    )
  }

  render() {
    const { book, bookProgress, exercises, country, bookContext } = this.props
    if (
      exercises.length > 0 &&
      ((bookContext === BookContext.reading && bookProgress.index < exercises.length) ||
        (bookContext === BookContext.listening && bookProgress.indexListening < exercises.length))
    ) {
      return (
        <div className="book-factory">
          <ExerciseTransition
            getSanaScore={(opt: GetSanaScoreOptions) => sanaGetScore({ ...opt, bookId: book._id })}
            country={country}
            onCurrentExerciseCompleted={this.onNextExercise}
            trackExerciseActivity={trackExerciseActivity}
            exercises={exercises}
            currentIndex={bookContext === BookContext.reading ? bookProgress.index : bookProgress.indexListening}
            bookId={book._id}
            bookContext={bookContext}
          />
        </div>
      )
    } else return null
  }
}

const connector = connect(
  (state: RootState) => ({
    exercises: selectAllExercises(state),
    country: selectUserCountry(state)
  }),
  {
    setActiveExerciseIndex,
    setCurrentBookExercisesAmount,
    updateOneBookProgress
  }
)

export default connector(BookFactory)
