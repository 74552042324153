import React, { useState, useCallback } from 'react'

import { Typography, LottieCharacter, CharacterMood, Book, GA_customDimensions, GA_event } from '@astrid/components'
import { GAEventCategory, GAEventActions } from 'utils/constants'
import BookButton from 'components/BookButton/BookButton'

import './JumpBackIn.scss'

interface IProps {
  book: Book
  name: string | undefined
}

const JumpBackIn: React.FC<IProps> = ({ book, name }) => {
  const [characterMood, setCharacterMood] = useState<CharacterMood>('idle')
  const onClick = useCallback(() => {
    GA_customDimensions(book.title)
    GA_event({
      category: GAEventCategory.BOOK,
      action: GAEventActions.BOOK_ENTER,
      label: 'Jump back in clicked'
    })
  }, [book])
  return (
    <div className="jump-back">
      <div className="jump-back__wrapper">
        <LottieCharacter
          mood={characterMood}
          changeCharacterMood={setCharacterMood}
          characterName="Alfred"
          neverBored
          className="jump-back__character"
        />
        <div className="jump-back__content">
          <Typography variant="h2" className="jump-back__title">
            Hi {name ? ` ${name}` : '!'}, welcome back!
            <br />
            Your recommended story.
          </Typography>
          <BookButton book={book} text="Recommended" onClick={onClick} color="light-blue" />
        </div>
      </div>
    </div>
  )
}

export default JumpBackIn
