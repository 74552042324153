import React from 'react'
import { useSelector } from 'react-redux'

import { getUserLevel, getUserLevelPercent } from 'utils'
import { CircularProgressBar } from '@astrid/components'
import { selectGlobalScore } from 'store/services/Auth/authReducer'

import './Level.scss'

const Level: React.FC = () => {
  const globalScore = useSelector(selectGlobalScore)
  const userLevel = getUserLevel(globalScore)
  const levelPercentage = getUserLevelPercent(globalScore)

  return (
    <div className="level">
      <CircularProgressBar
        backgroundColor="#18acb7"
        progressColor="#6fe2dc"
        percentage={levelPercentage}
        size={24}
        strokeWidth={5}
      />
      <span className="level__text">
        <span className="level__text--disapearing">Level</span>
        {userLevel}
      </span>
    </div>
  )
}
export default Level
