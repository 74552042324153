import React from 'react'
import { useHistory } from 'react-router-dom'

import { Typography, TiltingCard } from '@astrid/components'
import { routes } from 'routes/RouterStackComponent'

import WordCraftBg from 'resources/graphics/astrid_levelup_wordcraft.png'
import SentenceBuilderBg from 'resources/graphics/astrid_levelup_sentencebuilder.png'
import WordRaceBg from 'resources/graphics/astrid_levelup_wordrace.png'

import './LevelUpCarousel.scss'

interface CardProps {
  text: string
  route: string
  backgroundImg: string
}

const LevelUpCard: React.FC<CardProps> = ({ text, route, backgroundImg }) => {
  const history = useHistory()
  return (
    <TiltingCard
      className="level-up-carousel__card"
      style={{ backgroundImage: `url(${backgroundImg})` }}
      onClick={() => history.push(`${routes.exercises}/${route}`)}>
      <Typography variant="label" component="span">
        Level up
      </Typography>
      <Typography variant="h4">{text}</Typography>
    </TiltingCard>
  )
}

const LevelUpCarousel: React.FC = () => {
  return (
    <div className="level-up-carousel">
      <LevelUpCard backgroundImg={SentenceBuilderBg} text="Sentence builder" route={routes.sentence_builder} />
      <LevelUpCard backgroundImg={WordCraftBg} text="Word Craft" route={routes.word_craft} />
      <LevelUpCard backgroundImg={WordRaceBg} text="Word race" route={routes.word_race} />
    </div>
  )
}

export default LevelUpCarousel
