import React from 'react'
import { Form, Formik } from 'formik'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'
import { IUser } from 'store/services/Auth/types'
import { updateUser } from 'store/services/Auth/authReducer'
import { Typography, Button, EditCircleIcon, countries, ValidationRules } from '@astrid/components'

import styles from './EditProfileForm.module.scss'

interface ProfileCustomInputProps {
  name: string
  value: string
  onChange: (e: any) => void
  error?: string
  disabled?: boolean
}

const ProfileCustomInput: React.FC<ProfileCustomInputProps> = ({ name, value, onChange, disabled, error }) =>
  disabled ? (
    <span>{value}</span>
  ) : (
    <label className={styles.customInput} data-invalid={!!error}>
      {!disabled && <EditCircleIcon />}
      <input name={name} type="text" value={value} onChange={onChange} />
      {error && <span>{error}</span>}
    </label>
  )

const validationSchema = Yup.object().shape({
  firstName: ValidationRules.name.required('Required'),
  lastName: ValidationRules.name.required('Required'),
  parentName1: ValidationRules.name,
  parentName2: ValidationRules.name,
  parentEmail1: ValidationRules.emailNotRequired,
  parentEmail2: ValidationRules.emailNotRequired
})

const EditProfileForm: React.FC<{ user: IUser; onSubmit?: () => void }> = ({ user, onSubmit }) => {
  const dispatch = useDispatch()
  const country = countries.find(({ code }) => code === user.profile.country)
  return (
    <Formik
      enableReinitialize={true}
      validationSchema={validationSchema}
      initialValues={{
        firstName: user.profile.firstName,
        lastName: user.profile.lastName,
        parentName1: user.profile.parent?.name1 || '',
        parentName2: user.profile.parent?.name2 || '',
        parentEmail1: user.profile.parent?.email1 || '',
        parentEmail2: user.profile.parent?.email2 || ''
      }}
      onSubmit={async (values) => {
        await dispatch(
          updateUser({
            firstName: values.firstName,
            lastName: values.lastName,
            parent: {
              name1: values.parentName1 !== '' ? values.parentName1 : null,
              name2: values.parentName2 !== '' ? values.parentName2 : null,
              email1: values.parentEmail1 !== '' ? values.parentEmail1 : null,
              email2: values.parentEmail2 !== '' ? values.parentEmail2 : null
            }
          })
        )
        if (onSubmit) await onSubmit()
      }}>
      {({ values, handleChange, dirty, errors, submitForm }) => (
        <Form className={styles.container}>
          <span className={styles.row}>
            <Typography variant="body">First name</Typography>
            <ProfileCustomInput
              name="firstName"
              value={values.firstName}
              onChange={handleChange}
              error={errors.firstName}
            />
          </span>
          <span className={styles.row}>
            <Typography variant="body">Last name</Typography>
            <ProfileCustomInput
              name="lastName"
              value={values.lastName}
              onChange={handleChange}
              error={errors.lastName}
            />
          </span>
          <span className={styles.row}>
            <Typography variant="body" color="secondary">
              Username / email
            </Typography>
            <span>{user.username}</span>
          </span>
          <span className={styles.row}>
            <Typography variant="body" color="secondary">
              School name
            </Typography>
            <span>{user.profile.school.name}</span>
          </span>
          <span className={styles.row}>
            <Typography variant="body" color="secondary">
              Grade
            </Typography>
            <span>{user.profile.grade}</span>
          </span>
          <span className={styles.row}>
            <Typography variant="body" color="secondary">
              Country
            </Typography>
            <span>{country?.name}</span>
          </span>

          <span className={styles.row}>
            <Typography variant="body">Parent name 1</Typography>
            <ProfileCustomInput
              name="parentName1"
              value={values.parentName1}
              onChange={handleChange}
              error={errors.parentName1}
            />
          </span>
          <span className={styles.row}>
            <Typography variant="body">Parent name 2</Typography>
            <ProfileCustomInput
              name="parentName2"
              value={values.parentName2}
              onChange={handleChange}
              error={errors.parentName2}
            />
          </span>
          <span className={styles.row}>
            <Typography variant="body">Parent email 1</Typography>
            <ProfileCustomInput
              name="parentEmail1"
              value={values.parentEmail1}
              onChange={handleChange}
              error={errors.parentEmail1}
            />
          </span>
          <span className={styles.row}>
            <Typography variant="body">Parent email 2</Typography>
            <ProfileCustomInput
              name="parentEmail2"
              value={values.parentEmail2}
              onChange={handleChange}
              error={errors.parentEmail2}
            />
          </span>
          <div className={styles.controls}>
            <Button type="submit" onClick={submitForm} disabled={!dirty} size="small">
              Save
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default EditProfileForm
