import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { selectUser } from 'store/services/Auth/authReducer'
import { Typography, CircularProgressBar, GA_pageview, useWindowSize, Button } from '@astrid/components'
import { getUserLevelPercent, getUserLevel } from 'utils'
import { useHistory } from 'react-router-dom'
import Rewards from 'components/Rewards/Rewards'

import './Profile.scss'
import { routes } from 'routes/RouterStackComponent'

const Profile: React.FC = () => {
  const user = useSelector(selectUser)
  const level = getUserLevel(user?.profile.globalScore)
  const percent = getUserLevelPercent(user?.profile.globalScore)
  const { width } = useWindowSize()
  const history = useHistory()

  useEffect(() => {
    GA_pageview('/profile')
  }, [])

  return (
    <div className="profile">
      <div className="profile__head">
        <div className="profile__head-display">
          <CircularProgressBar
            size={width! > 600 ? 100 : 80}
            strokeWidth={width! > 600 ? 13 : 10}
            backgroundColor="#18acb7"
            progressColor="#6fe2dc"
            percentage={percent}>
            <Typography variant="exerciseM">{level}</Typography>
          </CircularProgressBar>
          {user?.profile.avatar && <img className="profile__head-avatar" src={user?.profile.avatar} alt="Avatar" />}
        </div>
        <div className="profile__head-info">
          <Typography variant="h3">{`${user?.profile.firstName} ${user?.profile.lastName}`}</Typography>
          <Typography variant="exerciseS">
            Grade {user?.profile.grade} {user?.profile.school.name ? `, ${user?.profile.school.name}` : ''}{' '}
          </Typography>
        </div>
        <Button size="small" onClick={() => history.push(routes.profile_edit)}>
          Account settings
        </Button>
      </div>
      <Rewards rewards={user?.rewards || []} />
    </div>
  )
}

export default Profile
