import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import Login from 'views/Login/Login'
import Register from 'views/Register/Register'
import { routes } from './RouterStackComponent'
import ResetPassword from 'views/ResetPassword/ResetPassword'
import MobileAppDownload from 'views/MobileAppDownload/MobileAppDownload'
import RegretDeletion from 'views/RegretDeletion/RegretDeletion'

const AuthRouter: React.FC = () => {
  return (
    <Switch>
      <Route path={routes.home} exact component={Login} />
      <Route path={routes.register} exact component={Register} />
      {/* <Route path={routes.invitation} exact component={Register} /> */}
      {<Route path={routes.reset_password} exact component={ResetPassword} />}
      {<Route path={routes.app} exact component={MobileAppDownload} />}
      <Route path={routes.regret_deletion} exact component={RegretDeletion} />
      <Redirect to={routes.home} />
    </Switch>
  )
}

export default AuthRouter
