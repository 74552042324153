import React, { useCallback, useMemo, useState } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import GlobalScore from 'components/GlobalScore/GlobalScore'
import Level from 'components/Level/Level'

import { routes } from 'routes/RouterStackComponent'
import ExerciseProgressBar from 'components/ExerciseProgressBar/ExerciseProgressBar'

import { ReactComponent as AchievementIcon } from 'resources/graphics/achievements-icons/onboarding-5.svg'
import { ReactComponent as MenuIcon } from 'resources/graphics/icons/menu.svg'
import { ReactComponent as AccountIcon } from 'resources/graphics/icons/account.svg'
import { AstridLogo, CloseIcon, HelpIcon } from '@astrid/components'

import './Header.scss'
import { useSelector } from 'react-redux'
import { selectUser } from 'store/services/Auth/authReducer'

const Wrapper: React.FC = ({ children }) => <span className="header__badge-wrapper">{children}</span>

const Header: React.FC<RouteComponentProps> = (props) => {
  const [collapsableOpen, setCollapsableOpen] = useState(false)
  const user = useSelector(selectUser)

  const { pathname } = props.location
  const isBookRoute = pathname.includes(routes.book)
  const isConratulationRoute = pathname.includes(routes.congratulation)
  const isExerciseRoute = pathname.includes(routes.exercises)
  const isProfileRoute = pathname.includes(routes.profile)
  const showCloseButton = isBookRoute || isConratulationRoute || isProfileRoute || isExerciseRoute

  const reloadWindow = useCallback(() => {
    window.location.reload()
  }, [])

  const userInfoContent = useMemo(
    () => (
      <>
        <Wrapper>
          <GlobalScore />
        </Wrapper>
        <Wrapper>
          <Level />
        </Wrapper>
        {user?.rewards && (
          <Wrapper>
            <AchievementIcon className="header__achievement-icon" />
            {user.rewards.length}
          </Wrapper>
        )}
        <Wrapper>
          <AccountIcon
            className="header__profile-icon"
            onClick={() => {
              setCollapsableOpen(false)
              props.history.push(routes.profile)
            }}
          />
        </Wrapper>
        <Wrapper>
          <a href="https://www.astrideducation.com/faq" target="_blank" rel="noopener noreferrer">
            <HelpIcon className="header__profile-icon" />
          </a>
        </Wrapper>
      </>
    ),
    // eslint-disable-next-line
    [user]
  )

  return (
    <header className="header">
      <div className="header__container">
        {showCloseButton ? (
          <CloseIcon className="header__close" onClick={() => props.history.replace('/')} />
        ) : (
          <AstridLogo onClick={reloadWindow} className="header__logo" />
        )}
        <div className="header__user-info">{userInfoContent}</div>
        <MenuIcon className="header__menu-icon" onClick={() => setCollapsableOpen((val) => !val)} />
      </div>
      <div className="header__progress-bar-container">{isBookRoute && <ExerciseProgressBar />}</div>
      <div className={`collapsable-bar${collapsableOpen ? '--open' : ''}`}>{userInfoContent}</div>
    </header>
  )
}

export default withRouter(Header)
