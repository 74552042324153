import { AxiosPromise } from 'axios'

import api from './api'
import { ScoreResponse, GetSanaScoreOptions } from '@astrid/components'
import { getLanguage } from 'utils'

export const sanaGetScore = ({
  audio,
  phrase,
  bookId,
  withoutAuth
}: GetSanaScoreOptions): AxiosPromise<ScoreResponse> => {
  const formData = new FormData()
  formData.append('audio', audio)
  formData.append('target_phrase', phrase)
  formData.append('dialect', getLanguage())
  if (bookId) formData.append('bookId', bookId)

  return api.post(`/sana${withoutAuth ? '/public' : ''}/score`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}
