import React, { useCallback } from 'react'
import { Typography } from '@astrid/components'
import { IReward } from 'store/services/Auth/types'
import AchievementIcon from 'components/AchievementHandler/AchievementIcon'
import { GoalNames, AchievementName } from 'store/services/Auth/types'

import './Rewards.scss'

interface Props {
  rewards: IReward[]
}

const Rewards: React.FC<Props> = ({ rewards }) => {
  const mapGoalName = useCallback((goal: GoalNames): string => {
    switch (goal) {
      case GoalNames.BooksFinished:
        return 'Finished books'
      case GoalNames.ExercisesFinished:
        return 'Finished exercises'
      case GoalNames.TimeSpent:
        return 'Exercise time'
      default:
        return ''
    }
  }, [])

  return (
    <div className="rewards">
      <Typography variant="h2">My achievements</Typography>
      <div className="rewards-wrapper">
        {rewards.length === 0 && (
          <Typography variant="exerciseS">Continue reading to reach goals and receive rewards!</Typography>
        )}
        {rewards.map((reward, i) => (
          <div key={`${reward.name}${i}`} className="achievement">
            <AchievementIcon reward={reward} />
            <div>
              <span className="achievement-name">{reward.name}</span>
              {reward.goal && <span className="achievement-description">{mapGoalName(reward.goal)}</span>}
              {reward.score && <span className="achievement-description">Score: {reward.score}</span>}
              {reward.name === AchievementName.NEW_RECORD && reward.exerciseType && (
                <span className="achievement-description">{reward.exerciseType}</span>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Rewards
