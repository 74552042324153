import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'

import { getUser, selectUser } from 'store/services/Auth/authReducer'
// import InvitationApi from 'api/invitationApi'
import AuthRouter from 'routes/AuthRouter'
// import VerifyEmail from 'views/VerifyEmail/VerifyEmail'
import { WelcomeAnimation } from '@astrid/components'
import EmailConfirmed from 'views/EmailConfirmed/EmailConfirmed'

const AuthWall: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(true)
  const [welcomeAnimation, setWelcomeAnimation] = useState(true)
  const dispatch = useDispatch()
  const user = useSelector(selectUser)
  // const matchInvitation = useRouteMatch<{ id: string }>({
  //   path: '/invitation/:id',
  //   strict: true,
  //   exact: true
  // })

  const matchEmail = useRouteMatch<{ token: string }>({
    path: '/auth/verify/:token',
    strict: true,
    exact: true
  })

  // const matchEmailChange = useRouteMatch<{ token: string }>({
  //   path: '/auth/verify-change/:token',
  //   strict: true,
  //   exact: true
  // })

  useEffect(() => {
    // @ts-ignore
    const isChrome = !!window.chrome && (!!window.chrome?.webstore || !!window.chrome?.runtime)
    const isIOS = navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)

    // if (matchInvitation) {
    //   InvitationApi.storeInvitationId(matchInvitation.params.id)
    // }

    if (isChrome && isIOS) {
      alert('The app will not work properly with Chrome on iOS. Please use Safari browser when on iOS device.')
    }

    ;(async () => {
      // if (matchEmailChange) {
      //   try {
      //     await api.get(`/auth/verify-change/${matchEmailChange.params.token}`)
      //   } catch (error) { }
      // }

      try {
        await dispatch(getUser())
      } catch (error) {
      } finally {
        setLoading(false)
      }
    })()
    // eslint-disable-next-line
  }, [])

  if (loading) {
    return null
  } else if (matchEmail) {
    return <EmailConfirmed token={matchEmail.params.token} />
  } else if (!user) {
    return <AuthRouter />
  } else if (user && welcomeAnimation && process.env.NODE_ENV !== 'development') {
    return <WelcomeAnimation onAnimationEnd={() => setWelcomeAnimation(false)} />
  } else {
    return <>{children}</>
  }
}

export default AuthWall
