export enum GAEventCategory {
  BOOK = 'Book',
  USER = 'User',
  EXERCISE = 'Exercise'
}

export enum GAEventActions {
  BOOK_FINISHED = 'Book finished',
  BOOK_EXIT = 'Book exit',
  PROFILE_CREATE = 'Create profile',
  REGISTER = 'Register',
  START_ONBOARDING = 'Start onboarding',
  FINISH_ONBOARDING = 'Onboarding finished',
  BOOK_ENTER = 'Book enter'
}
