import React, { useEffect, useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import { routes } from 'routes/RouterStackComponent'
import {
  Book,
  GA_event,
  BookRate,
  GA_pageview,
  GA_customDimensions,
  BookContext,
  BookType,
  sortBooksByCEFR
} from '@astrid/components'
import { selectProfile, updateGlobalScore } from 'store/services/Auth/authReducer'
import {
  updateOneBookProgress,
  selectBookProgressById,
  rateBook
} from 'store/services/BookProgress/bookProgressReducer'
import { RootState } from 'store/types'
import Congratulations from './Congratulations/Congratulations'
import { GAEventCategory, GAEventActions } from 'utils/constants'
import { selectAllBooks } from 'store/services/Book/bookReducer'

interface LocationState {
  book: Book
  bookContext: BookContext
}

interface IProps extends RouteComponentProps<{}, {}, LocationState> {}

const FinishedBook: React.FC<IProps> = ({
  history,
  location: {
    state: { book, bookContext }
  }
}) => {
  if (!book || !bookContext) history.push(routes.home)

  const dispatch = useDispatch()
  const profile = useSelector(selectProfile)
  const bookProgress = useSelector((state: RootState) => selectBookProgressById(state, book._id))
  const contextBooks = sortBooksByCEFR(
    useSelector(selectAllBooks).filter((bk) => {
      if (bookContext === BookContext.listening) return bk.bookType !== BookType.Reading
      if (bookContext === BookContext.reading) return bk.bookType !== BookType.Listening
      return true
    })
  )
  const [starsFlying, setStarsFlying] = useState(false)
  const [displayRate, setDisplayRate] = useState(true)
  const nextBook = contextBooks[contextBooks.findIndex((bk) => bk._id === book._id) + 1]

  if (!bookProgress) history.push(routes.home)

  const _rateBook = useCallback(
    async (rate: BookRate) => {
      if (bookProgress) {
        await dispatch(rateBook(bookProgress._id, rate))
        setDisplayRate(false)
      }
    },
    [bookProgress, dispatch]
  )

  const onDidMount = () => {
    const shouldUpdateReading = bookContext === BookContext.reading && bookProgress && !bookProgress.isCompleted
    const shouldUpdateListening =
      bookContext === BookContext.listening && bookProgress && !bookProgress.isCompletedListening

    if (shouldUpdateReading || shouldUpdateListening) {
      GA_pageview('/congratulations')
      GA_customDimensions(book.title, null, null, 1)
      GA_event({
        category: GAEventCategory.BOOK,
        action: GAEventActions.BOOK_FINISHED,
        label: 'Book finished'
      })
      GA_customDimensions()

      dispatch(
        updateOneBookProgress(
          bookProgress!._id,
          bookContext === BookContext.reading
            ? {
                index: bookProgress!.index,
                isCompleted: true
              }
            : {
                indexListening: bookProgress!.index,
                isCompletedListening: true
              }
        )
      )

      setTimeout(() => {
        setStarsFlying(true)
        dispatch(updateGlobalScore(book.totalPoints))
      }, 2000)
    }
  }

  useEffect(onDidMount, [])

  return (
    <Congratulations
      name={profile!.firstName}
      bookTitle={book.title}
      bookTotalPoints={book.totalPoints}
      globalScore={profile!.globalScore!}
      bookContext={bookContext}
      starsFlying={starsFlying}
      displayRate={displayRate}
      rateBook={_rateBook}
      displayNextBook={!!nextBook}
      nextBook={nextBook}
    />
  )
}

export default withRouter(FinishedBook)
