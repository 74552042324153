import React, { useRef, useEffect, useState } from 'react'
import lottie from 'lottie-web'

import AnimationNoScale from './astrid_intro_animation_no_scale_square.json'
import IntroSound from '../../audio/intro.mp3'

import './WelcomeAnimation.scss'

interface WelcomeAnimationProps {
  onAnimationStart?: () => void
  onAnimationEnd?: () => void
}

const WelcomeAnimation: React.FC<WelcomeAnimationProps> = ({ onAnimationStart, onAnimationEnd }) => {
  const animRef = useRef<HTMLDivElement>(null)
  const audioRef = useRef<HTMLAudioElement>(null)
  const [fadeOut, setFadeOut] = useState(false)
  const [audioPlaying, setAudioPlaying] = useState(false)

  useEffect(() => {
    const animation = lottie.loadAnimation({
      container: animRef.current!,
      animationData: AnimationNoScale,
      autoplay: false,
      loop: false,
      name: 'Welcome animation',
      renderer: 'svg'
    })
    if (onAnimationStart) onAnimationStart()

    animation.addEventListener('enterFrame', () => {
      setAudioPlaying(true)
    })

    animation.addEventListener('complete', () => {
      setFadeOut(true)
      audioRef.current!.pause()
    })

    animation.play()
  }, [])

  const onFinish = () => {
    if (fadeOut && onAnimationEnd) {
      onAnimationEnd()
    }
  }

  useEffect(() => {
    if (audioPlaying && audioRef.current!.paused) {
      audioRef
        .current!.play()
        .then(() => {})
        .catch(() => {})
    }
  }, [audioPlaying])

  return (
    <>
      <div
        ref={animRef}
        onAnimationEnd={onFinish}
        className={`welcome-animation welcome-animation__${fadeOut ? 'fade' : 'background'}`}
      />
      <audio ref={audioRef} loop={false}>
        <source src={IntroSound} type="audio/mpeg" />
      </audio>
    </>
  )
}

export default WelcomeAnimation
