import React, { useState, useEffect } from 'react'

import { StarIcon, withSoundEffectsContext, InjectedSoundProps } from '@astrid/components'

import './FlyingStars.scss'

interface IProps extends InjectedSoundProps {
  startFlight: boolean
  mainStarSize?: number
}

const STARS_TO_RENDER: number = 12

const FlyingStars: React.FC<IProps> = ({ startFlight, mainStarSize = 64, playSound }) => {
  const containerRef = React.createRef<HTMLDivElement>()
  const flyingTarget = document.getElementById('global-score-star-icon')
  const [containerCoords, setCointainerCoords] = useState({ left: 0, top: 0 })
  const [animationEnded, setAnimationEnded] = useState(false)
  const starOffset = mainStarSize / 2 - 12

  useEffect(() => {
    // Get the coordinates of the wrapping div
    const { left, top } = containerRef.current!.getBoundingClientRect()
    setCointainerCoords({ left, top })
    playSound('star-progress')
    // eslint-disable-next-line
  }, [])

  // Get the coordinates of the target
  const targetCoords = (() => {
    const { left, top } = flyingTarget!.getBoundingClientRect()
    return { left, top }
  })()

  // Calculate distance between initial position and target position:
  const leftTransform = (() => {
    const value = containerCoords.left - targetCoords.left
    return value * -1 - starOffset
  })()

  const topTransform = (() => {
    const value = containerCoords.top - targetCoords.top
    if (value > 0) {
      return value * -1 - starOffset
    } else {
      return value - starOffset
    }
  })()

  // Construct translation from initial position to target position:
  const targetTransform = `translate(${leftTransform}px, ${topTransform}px)`

  // Generate stars:
  const stars = (() => {
    const arr: JSX.Element[] = []
    for (let i = 0; i < STARS_TO_RENDER; i++) {
      arr.push(
        <StarIcon
          key={`star${i}`}
          onTransitionEnd={
            i === STARS_TO_RENDER - 1
              ? () => {
                  setAnimationEnded(true)
                }
              : undefined
          }
          className="flying-stars__star"
          style={{
            transform: startFlight ? targetTransform : undefined,
            top: starOffset,
            left: starOffset,
            transitionDelay: `${i * 120}ms`
          }}
        />
      )
    }
    return arr
  })()

  return (
    <div className="flying-stars" ref={containerRef}>
      <StarIcon
        className={`flying-stars__main-star${startFlight ? '--pulse' : ''}`}
        style={{ width: `${mainStarSize}px`, height: `${mainStarSize}px` }}
      />
      {!animationEnded && window.innerWidth > 600 && stars}
    </div>
  )
}

export default withSoundEffectsContext(FlyingStars)
