import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { CONFIG } from 'config'
import { ReactComponent as StarIcon } from 'resources/graphics/icons/star-filled.svg'
import { selectGlobalScore } from 'store/services/Auth/authReducer'

import './GlobalScore.scss'

const GlobalScore: React.FC = () => {
  const globalScore = useSelector(selectGlobalScore)
  const defaultScore = parseInt(CONFIG.DEFAULT_SCORE.toString())

  const [displayedScore, setDisplayedScore] = useState(defaultScore + globalScore)

  useEffect(() => {
    let interval: any
    interval = setInterval(() => {
      setDisplayedScore((prevDisplayedScore) => {
        const increment = globalScore - prevDisplayedScore > 200 ? 50 : 1
        const newDisplayedScore = prevDisplayedScore + increment

        if (newDisplayedScore > globalScore + defaultScore) {
          clearInterval(interval)
          return prevDisplayedScore
        } else {
          return newDisplayedScore
        }
      })
    }, 30)
  }, [globalScore, defaultScore])

  return (
    <div className="global-score">
      <StarIcon id="global-score-star-icon" />
      <div className="global-score__score">{displayedScore}</div>
    </div>
  )
}

export default GlobalScore
