import React, { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import { Book, BookContext, Button, BookType } from '@astrid/components'
import { routes } from 'routes/RouterStackComponent'
import { ReactComponent as PlayIcon } from 'resources/graphics/icons/play.svg'

import './BookButton.scss'

interface BookButtonProps {
  book: Book
  text: string
  color?: 'default' | 'light-blue'
  onClick?: () => void
}

const BookButton: React.FC<BookButtonProps> = ({ book, text, onClick, color = 'default' }) => {
  const history = useHistory()
  const bookContext: BookContext = useMemo(() => {
    if (book.bookType === BookType.Reading) return BookContext.reading
    if (book.bookType === BookType.Listening) return BookContext.listening
    return BookContext.reading
  }, [book])

  const handleClick = useCallback(() => {
    if (onClick) onClick()
    history.push(`${routes.book}/${bookContext}/${book._id}`)
  }, [onClick, history, book, bookContext])

  return (
    <Button onClick={handleClick} color={color}>
      <div className="book-button">
        <img src={book.imageUrl} alt="" />
        <div>
          <span>{book.title}</span>
          <p>{text}</p>
        </div>
        <PlayIcon />
      </div>
    </Button>
  )
}

export default BookButton
