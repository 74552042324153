import React, { useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { updateGlobalScore, selectUserCountry } from 'store/services/Auth/authReducer'
import { ExerciseTransition, ExerciseType, BuildingExerciseData, IExercise } from '@astrid/components'
import { trackExerciseActivity, exerciseScore } from 'api/activityApi'
import { sanaGetScore } from 'api/sanaApi'

import './LevelUpFactory.scss'
import { useHistory } from 'react-router-dom'
import { routes } from 'routes/RouterStackComponent'

interface IProps {
  exercises: IExercise[]
}

const LevelUpFactory: React.FC<IProps> = ({ exercises }) => {
  const [exerciseIndex, setExerciseIndex] = useState(0)
  const dispatch = useDispatch()
  const history = useHistory()

  const userCountry = useSelector(selectUserCountry)

  const assignStars = useCallback(
    async (type: ExerciseType, percentageScore?: number) => {
      if (type === ExerciseType.ReadWordRace && typeof percentageScore === 'number' && percentageScore > 60) {
        try {
          await dispatch(updateGlobalScore(50))
        } catch (error) {}
      }

      if (type === ExerciseType.SentenceBuilder || type === ExerciseType.WordBuilder) {
        try {
          await dispatch(updateGlobalScore(25))
        } catch (error) {}
      }
    },
    [dispatch]
  )

  const nextExercise = useCallback(
    () =>
      setExerciseIndex((current) => {
        const nextIndex = current + 1
        if (nextIndex >= exercises.length) {
          return 0
        }
        return nextIndex
      }),
    [exercises.length]
  )

  const onNextExercise = async (exercisePercentageScore?: number) => {
    const currentExercise = exercises[exerciseIndex]
    const { MultiChoiseQuestion, WordBuilder } = ExerciseType
    const { type } = currentExercise

    if ([MultiChoiseQuestion, WordBuilder].includes(type)) {
      try {
        exerciseScore(
          exercisePercentageScore!,
          type,
          type === WordBuilder ? (currentExercise.data as BuildingExerciseData).wordOrSentence : undefined
        )
      } catch (error) {}
    }

    if (exerciseIndex === exercises.length - 1) {
      assignStars(type, exercisePercentageScore)
      history.replace(routes.home)
      return
    }

    nextExercise()
  }

  return (
    <div className="level-up-factory">
      <ExerciseTransition
        exercises={exercises}
        country={userCountry}
        currentIndex={exerciseIndex}
        getSanaScore={sanaGetScore}
        onCurrentExerciseCompleted={onNextExercise}
        trackExerciseActivity={trackExerciseActivity}
      />
    </div>
  )
}

export default LevelUpFactory
