import React, { useCallback, useContext, useMemo } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import * as Yup from 'yup'
import { Formik, FormikHelpers } from 'formik'
import { Typography, TextInput, Button, AlertContext } from '@astrid/components'
import { useDispatch } from 'react-redux'

import { ValidationRules } from 'utils/ValidationRules'
import { LoginCredentials } from 'store/services/Auth/types'
import { login } from 'store/services/Auth/authReducer'
import AuthWrapper from 'components/AuthWrapper/AuthWrapper'
import { printApiMessage } from 'api/apiMessages'

import './Login.scss'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import { routes } from 'routes/RouterStackComponent'

const validationSchema = Yup.object().shape({
  username: ValidationRules.required,
  password: ValidationRules.required
})

const Login: React.FC = () => {
  const dispatch = useDispatch()
  const { showAlert } = useContext(AlertContext)
  const location = useLocation()
  const initialValues = useMemo(() => {
    const params = new URLSearchParams(location.search)
    return {
      username: params.get('username') || '',
      password: params.get('password') || ''
    }
  }, [location.search])
  // const [recoverPasswordModalOpen, setRecoverPasswordModalOpen] = useState(false)

  const handleSubmit = useCallback(
    async (
      { username, password, keepSignedIn }: LoginCredentials,
      { setSubmitting }: FormikHelpers<LoginCredentials>
    ) => {
      try {
        await dispatch(login({ username, password, keepSignedIn }))
      } catch ({ message }) {
        showAlert(printApiMessage(message))
        setSubmitting(false)
      }
    },
    // eslint-disable-next-line
    []
  )

  return (
    <AuthWrapper>
      <Typography variant="h1" style={{ margin: 0 }}>
        Log in
      </Typography>
      <Typography variant="body">with your email and password</Typography>

      <Formik
        initialValues={{ username: initialValues.username, password: initialValues.password, keepSignedIn: false }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}>
        {({ values, touched, errors, handleChange, setFieldTouched, handleSubmit, isSubmitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              <TextInput
                name="username"
                label="Your Username/Email"
                value={values.username}
                onChange={handleChange('username')}
                error={touched.username && !!errors.username}
                helperText={(touched.username && errors.username) || ''}
                onBlur={() => setFieldTouched('username')}
              />
              <TextInput
                name="password"
                label="Enter Password"
                type="password"
                value={values.password}
                onChange={handleChange('password')}
                error={touched.password && !!errors.password}
                helperText={(touched.password && errors.password) || ''}
                onBlur={() => setFieldTouched('password')}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.keepSignedIn}
                    onChange={handleChange('keepSignedIn')}
                    name="keepSignedIn"
                    color="primary"
                    value={values.keepSignedIn}
                  />
                }
                label={<span>Keep me signed in</span>}
              />
              <Button disabled={isSubmitting}>Log in</Button>
            </form>
          )
        }}
      </Formik>
      <Typography variant="body" color="secondary" className="login__sign-in-link">
        Don't have an account? <NavLink to={routes.register}>Sign up</NavLink>
      </Typography>
      {/* <Typography
        variant="body"
        color="secondary"
        className="login__forgot"
        onClick={() => setRecoverPasswordModalOpen(true)}>
        Forgot password?
      </Typography>
      <Modal
        variant="student"
        hideCloseButton
        open={recoverPasswordModalOpen}
        closeModal={() => setRecoverPasswordModalOpen(false)}>
        <RecoverPassword closeModal={() => setRecoverPasswordModalOpen(false)} />
      </Modal> */}
    </AuthWrapper>
  )
}

export default Login
