import { initialize, event, pageview, set } from 'react-ga'

const DEVELOPMENT_TRACKING_CODE = 'UA-172769777-1'

const GA_init = (trackingCode: any) => {
  if (typeof trackingCode !== 'string') {
    console.error('No or invalid analytics ID provided.')
  }

  if (process.env.NODE_ENV === 'production' && typeof trackingCode === 'string') {
    initialize(trackingCode)
  } else {
    initialize(DEVELOPMENT_TRACKING_CODE, { testMode: process.env.NODE_ENV === 'test' })
  }
}

const GA_customDimensions = (
  bookTitle: string | null = null,
  timeSpentInBook: number | null = null,
  viewCount: number | null = null,
  finishCount: number | null = null
) =>
  set({
    dimension1: bookTitle,
    metric1: timeSpentInBook,
    metric2: viewCount,
    metric3: finishCount
  })

export { GA_init, event as GA_event, pageview as GA_pageview, GA_customDimensions }
