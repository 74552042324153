import { AxiosPromise } from 'axios'
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react'

import { Typography, ListenSentence, AudioRecorder, ArrowRight } from '../../index'
import { ScoreResponse, GetSanaScoreOptions } from '../../types'
import Button from '../Button/Button'

import './DifficultWords.scss'

interface IProps {
  difficultWords: { word: string; score: number }[]
  country?: string
  getSanaScore: (options: GetSanaScoreOptions) => AxiosPromise<ScoreResponse>
  onFinish: () => void
}

interface IState {
  mistakeCount: number
  recording: Blob | null
  sanaRate: ScoreResponse | null
}

const DifficultWords: React.FC<IProps> = ({ country, getSanaScore, difficultWords, onFinish }) => {
  const [state, setState] = useState<IState>({
    mistakeCount: 0,
    recording: null,
    sanaRate: null
  })
  const listenSentenceRef = useRef<ListenSentence>(null)

  const wordsJoinedWithDots = useMemo(
    () =>
      [...difficultWords]
        .sort((a, b) => b.score - a.score)
        .map((v) => v.word)
        .join('. '),
    [difficultWords]
  )

  const handleRecording = useCallback(
    async (recording: Blob) => {
      try {
        const { data } = await getSanaScore({ audio: recording, phrase: wordsJoinedWithDots })
        setState((state) => ({ ...state, sanaRate: data, recording }))
        rate(data)
      } catch (error) {
        setState((state) => ({ ...state, recording }))
      }
    },
    [wordsJoinedWithDots]
  )

  const onStartRecording = useCallback(() => {
    listenSentenceRef.current?.stopPlaying()
    setState((state) => ({ ...state, recording: null, sanaRate: null }))
  }, [])

  const rate = useCallback(
    (data: ScoreResponse) => {
      const { word_scores } = data
      if (word_scores.every((score) => score.score > 75)) {
        onFinish()
      } else {
        setState((state) => ({
          ...state,
          mistakeCount: state.mistakeCount + 1
        }))
      }
    },
    [onFinish]
  )

  const renderWords = () => {
    const { sanaRate } = state

    if (sanaRate && sanaRate.word_scores && sanaRate.word_scores.length > 0) {
      return sanaRate.word_scores.map((word, i) => {
        const color = word.score > 75 ? 'great' : word.score < 60 ? 'wrong' : 'ok'
        return (
          <Typography key={`${word.word}${i}`} variant="exerciseL" className={`difficult-words__score-color--${color}`}>
            {word.word.replace('.', '')}
          </Typography>
        )
      })
    }

    return wordsJoinedWithDots.split('. ').map((word, i) => (
      <Typography key={`${word}${i}`} variant="exerciseL">
        {word}
      </Typography>
    ))
  }

  useEffect(() => {
    setState({
      mistakeCount: 0,
      recording: null,
      sanaRate: null
    })
  }, [difficultWords])

  return (
    <div className="difficult-words">
      <div className="difficult-words__listen-button">
        <ListenSentence
          ref={listenSentenceRef}
          sentence={wordsJoinedWithDots}
          country={country}
          jump={state.mistakeCount !== 0 && state.mistakeCount % 3 === 0}
        />
      </div>
      <div className="difficult-words__content">
        <div className="difficult-words__words">{renderWords()}</div>
        <AudioRecorder onStopRecording={handleRecording} onStartRecording={onStartRecording} />
      </div>
      {state.mistakeCount > 2 && (
        <Button onClick={onFinish} className="difficult-words__finish">
          Finish <ArrowRight />
        </Button>
      )}
    </div>
  )
}

export default DifficultWords
