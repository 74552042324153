import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import { BookContext, BookCover, GA_pageview } from '@astrid/components'
import { routes } from 'routes/RouterStackComponent'
import BookFactory from 'components/BookFactory/BookFactory'

import { RootState } from 'store/types'
import { createNewBookProgess, selectBookProgressById } from 'store/services/BookProgress/bookProgressReducer'
import { getBookExercises, cleanExercisesState } from 'store/services/Exercise/exerciseReducer'
import { selectBookById } from 'store/services/Book/bookReducer'
import DifficultWordsPractise from 'views/DifficultWordsPractise/DifficultWordsPractise'

const BookView: React.FC<RouteComponentProps<{ id: string; bookContext: BookContext }>> = (props) => {
  const dispatch = useDispatch()
  const bookId = props.match.params.id
  const bookContext = props.match.params.bookContext

  if (!Object.values(BookContext).includes(bookContext)) {
    // Return home if the book context is incorrect
    props.history.push(routes.home)
  }

  const [coverOpen, setCoverOpen] = useState(true)
  const [loading, setLoading] = useState(true)

  const currentBook = useSelector((state: RootState) => selectBookById(state, bookId))
  const currentBookProgress = useSelector((state: RootState) => selectBookProgressById(state, bookId))

  const isListening = bookContext === BookContext.listening
  const isReading = bookContext === BookContext.reading

  const onDidMount = () => {
    GA_pageview('/book')
    ;(async () => {
      try {
        if (currentBook) await dispatch(getBookExercises(bookId))
        if (!currentBookProgress) await dispatch(createNewBookProgess(bookId))
      } finally {
        setLoading(false)
      }
    })()
  }

  const onUnmount = () => {
    dispatch(cleanExercisesState())
  }

  useEffect(() => {
    onDidMount()
    return onUnmount
    // eslint-disable-next-line
  }, [])

  const onCompleteBook = () => {
    if (currentBook && currentBookProgress) {
      props.history.replace(routes.congratulation, {
        book: currentBook,
        bookContext
      })
    }
  }

  if (!currentBook || !currentBookProgress) return null

  if (
    coverOpen &&
    ((isReading && currentBookProgress.index === 0) || (isListening && currentBookProgress.indexListening === 0))
  ) {
    return (
      <BookCover
        disableContinue={loading}
        book={currentBook}
        bookContext={bookContext}
        onStart={() => setCoverOpen(false)}
      />
    )
  }

  if (
    (currentBookProgress.index === currentBook.exercises.length && !currentBookProgress.isCompleted) ||
    (currentBookProgress.indexListening === currentBook.exercises.length && !currentBookProgress.isCompletedListening)
  ) {
    if (currentBookProgress.difficultWords.length > 0 && isReading) {
      return <DifficultWordsPractise bookProgress={currentBookProgress} onFinish={onCompleteBook} book={currentBook} />
    } else {
      onCompleteBook()
      return null
    }
  }

  return (
    <BookFactory
      book={currentBook}
      bookProgress={currentBookProgress}
      onCompleteBook={onCompleteBook}
      bookContext={bookContext}
    />
  )
}

export default withRouter(BookView)
