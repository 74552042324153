import { AxiosPromise } from 'axios'

import api from 'api/api'
import { Paginated } from 'utils/types'
import { Book } from '@astrid/components'

class BookApi {
  static getAllBooks = (): AxiosPromise<Paginated<Book>> =>
    api.get('/book', {
      params: {
        $limit: 150,
        $filter: 'platform:web'
      }
    })
}

export default BookApi
