import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { Formik } from 'formik'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'

import { GAEventCategory, GAEventActions } from 'utils/constants'
import { createProfile } from 'store/services/Auth/authReducer'
import { routes } from 'routes/RouterStackComponent'
import { Typography, Button, TextInput, Select, GA_pageview, GA_event, ValidationRules } from '@astrid/components'
import countries from '@astrid/components/src/utils/countries.json'

import './CreateProfile.scss'

interface FormValues {
  firstName: string
  lastName: string
  school: string
  country: string
  grade: string
}

const CreateProfile_: React.FC = () => {
  const countryOptions = useMemo(() => countries.map(({ code, name }) => ({ label: name, value: code })), [])
  const dispatch = useDispatch()
  const history = useHistory()

  const validationSchema = Yup.object().shape({
    firstName: ValidationRules.name.required('Required'),
    lastName: ValidationRules.name.required('Required'),
    school: ValidationRules.required,
    country: ValidationRules.required,
    grade: ValidationRules.required
  })

  const handleSubmit = useCallback(
    async (formValues: FormValues) => {
      try {
        await dispatch(createProfile({ ...formValues, school: { name: formValues.school } }))
        GA_event({
          category: GAEventCategory.USER,
          action: GAEventActions.PROFILE_CREATE,
          label: 'Create profile'
        })
        history.push(routes.home)
      } catch (error) {}
    },
    [dispatch, history]
  )

  useEffect(() => {
    GA_pageview('/create-profile')
  }, [])

  return (
    <div className="create-profile">
      <Typography className="create-profile__heading" variant="h2">
        Please fill the form to create a profile
      </Typography>
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          school: '',
          grade: '',
          country: ''
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}>
        {({ values, touched, errors, handleChange, setFieldTouched, handleSubmit, isSubmitting, setFieldValue }) => {
          const gradeOptions =
            values.country === 'US'
              ? [
                  { label: 'K', value: 'K' },
                  { label: '1', value: '1' },
                  { label: '2', value: '2' }
                ]
              : [
                  { label: '1', value: '1' },
                  { label: '2', value: '2' },
                  { label: '3', value: '3' }
                ]

          return (
            <form onSubmit={handleSubmit} id="create-profile-form" className="create-profile__form">
              <TextInput
                name="firstName"
                label="First Name"
                value={values.firstName}
                onChange={handleChange('firstName')}
                error={touched.firstName && !!errors.firstName}
                helperText={(touched.firstName && errors.firstName) || ''}
                onBlur={() => setFieldTouched('firstName')}
              />
              <TextInput
                name="lastName"
                label="Last Name"
                value={values.lastName}
                onChange={handleChange('lastName')}
                error={touched.lastName && !!errors.lastName}
                helperText={(touched.lastName && errors.lastName) || ''}
                onBlur={() => setFieldTouched('lastName')}
              />
              <TextInput
                name="school"
                label="School name"
                value={values.school}
                onChange={handleChange('school')}
                error={touched.school && !!errors.school}
                helperText={(touched.school && errors.school) || ''}
                onBlur={() => setFieldTouched('school')}
              />
              <Select
                label="Grade"
                name="grade"
                value={values.grade}
                options={gradeOptions}
                onChange={handleChange('grade')}
                error={touched.grade && !!errors.grade}
                onBlur={() => setFieldTouched('grade')}
                helperText={(touched.grade && errors.grade) || ''}
              />
              <Select
                label="Country"
                name="country"
                value={values.country}
                options={countryOptions}
                onChange={(e) => {
                  setFieldValue('country', e.target.value)
                  if (values.country === 'US' || e.target.value === 'US') {
                    setFieldValue('grade', '')
                  }
                }}
                error={touched.country && !!errors.country}
                onBlur={() => setFieldTouched('country')}
                helperText={(touched.country && errors.country) || ''}
              />
              <Button type="submit" disabled={isSubmitting}>
                Create
              </Button>
            </form>
          )
        }}
      </Formik>
    </div>
  )
}

/*

Submit logic with avatar: 

submit = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
  event.preventDefault()
  const { name, grade, avatar, country, parentEmail, parentName, schoolAddress, schoolName } = this.state
  const formData = new FormData()

  const school = {
    name: schoolName,
    address: schoolAddress
  }

  const parent = {
    name: parentName,
    email: parentEmail
  }

  if (avatar) formData.append('avatar', avatar)

  formData.append('name', name)
  formData.append('grade', grade)
  formData.append('country', country)
  formData.append('school', JSON.stringify(school))
  if (parent.name || parent.email) formData.append('parent', JSON.stringify(parent))

  try {
    await this.props.createProfile(formData)
    GA_event({
      category: GAEventCategory.USER,
      action: GAEventActions.PROFILE_CREATE,
      label: 'Create profile'
    })
    this.props.history.push(routes.home)
  } catch (error) {
    this.setState({
      error: true
    })
  }
}


Invitation logic:

async componentDidMount() {
  GA_pageview('/create-profile')
  const invitationId = InvitationApi.getInvitationId()
  if (invitationId) {
    const {
      data: { country, grade, school, name }
    } = await InvitationApi.getInvitationInfo(invitationId)
    this.setState({
      grade: grade,
      country: country,
      schoolName: school,
      name: name,
      prefilled: true
    })
  }
}

*/

export default CreateProfile_
