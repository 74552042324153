import React from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'

import AuthWall from './AuthWall/AuthWall'
import ProfileWall from './ProfileWall/ProfileWall'
import Header from 'components/Header/Header'
import BookView from 'views/BookView/BookView'
import Profile from 'views/Profile/Profile'
import Dashboard from 'views/Dashboard/Dashboard'
import FinishedBook from 'views/FinishedBook/FinishedBook'
import OnboardingFinished from 'views/FinishedBook/OnboardingFinished'
import LevelUp from 'views/LevelUp/LevelUp'
import AppcuesWrapper from 'components/Appcues/AppcuesWrapper'
import EditProfile from 'views/EditProfile/EditProfile'
import MobileAppDownload from 'views/MobileAppDownload/MobileAppDownload'

export const routes = {
  home: '/',
  profile: '/profile',
  profile_edit: '/profile/edit',
  book: '/book',
  exercises: '/exercises',
  sentence_builder: 'sentence-builder',
  word_race: 'word-race',
  word_craft: 'word-craft',
  congratulation: '/congratulation',
  createProfile: '/create-profile',
  register: '/register',
  onboarding_finished: '/onboarding-finished',
  invitation: '/invitation',
  reset_password: '/auth/reset-pass/:resetToken',
  regret_deletion: '/regret-delete-account',
  app: '/app'
}

const RouterStackComponent = () => (
  <Router>
    <AppcuesWrapper>
      <AuthWall>
        <Header />
        <ProfileWall>
          <Switch>
            <Route path={routes.home} exact component={Dashboard} />
            <Route path={routes.profile} exact component={Profile} />
            <Route path={routes.profile_edit} exact component={EditProfile} />
            <Route path={`${routes.book}/:bookContext/:id/`} exact component={BookView} />
            <Route path={`${routes.exercises}/:type`} exact component={LevelUp} />
            <Route path={`${routes.congratulation}`} exact component={FinishedBook} />
            <Route path={`${routes.onboarding_finished}`} exact component={OnboardingFinished} />
            <Route path={routes.app} exact component={MobileAppDownload} />
            <Redirect to={routes.home} />
          </Switch>
        </ProfileWall>
      </AuthWall>
    </AppcuesWrapper>
  </Router>
)

export default RouterStackComponent
