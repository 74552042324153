import React, { useState, useEffect } from 'react'
import { RouteChildrenProps } from 'react-router-dom'

import { routes } from 'routes/RouterStackComponent'
import { ExerciseType, IExercise, GA_pageview } from '@astrid/components'
import ExerciseApi from 'store/services/Exercise/api'
import { shuffleArray } from 'utils'
import LevelUpFactory from 'components/LevelUpFactory/LevelUpFactory'

interface IProps extends RouteChildrenProps<{ type: string }> {}

const LevelUp: React.FC<IProps> = ({ match, history }) => {
  const [shuffledExerciseData, setShuffledExerciseData] = useState<IExercise[] | null>(null)
  const allowedRoutes = [routes.sentence_builder, routes.word_craft, routes.word_race]
  const exerciseRoute = match?.params.type
  if (!exerciseRoute || !allowedRoutes.includes(exerciseRoute)) history.replace(routes.home)

  const mapRouteToExerciseType = {
    [routes.sentence_builder]: ExerciseType.SentenceBuilder,
    [routes.word_craft]: ExerciseType.WordBuilder,
    [routes.word_race]: ExerciseType.ReadWordRace
  }
  const exerciseType = mapRouteToExerciseType[exerciseRoute!]

  const fetchExercises = () => {
    ;(async () => {
      try {
        const { data } = await ExerciseApi.getLevelUpExercises(exerciseType)
        setShuffledExerciseData(shuffleArray(data))
      } catch (error) {}
    })()
  }

  useEffect(() => {
    fetchExercises()
    GA_pageview(window.location.pathname)
    // eslint-disable-next-line
  }, [])

  if (shuffledExerciseData) {
    return <LevelUpFactory exercises={shuffledExerciseData} />
  } else return null
}

export default LevelUp
