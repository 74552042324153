import React from 'react'
import { useHistory } from 'react-router-dom'
import AbbeHappy from 'resources/graphics/abbe_happy.png'
import { Typography, Button } from '@astrid/components'
import { useSelector } from 'react-redux'
import { selectUser } from 'store/services/Auth/authReducer'
import styles from './VerifyEmail.module.scss'
import AuthApi from 'store/services/Auth/api'

const VerifyEmail: React.FC = () => {
  const user = useSelector(selectUser)
  const history = useHistory()
  return (
    <div className={styles.container}>
      <img src={AbbeHappy} alt="Abbe" />
      <Typography variant="h3">Confirm your email</Typography>
      <Typography variant="body">We've sent you an activation email to: {user?.username}</Typography>
      <Button
        onClick={() => {
          history.push(`/`)
          AuthApi.logout()
        }}>
        Back to login
      </Button>
    </div>
  )
}

export default VerifyEmail
