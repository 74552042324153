import React from 'react'

import styles from './InvitationModal.module.scss'
import { DetailedInvitationInfo } from 'api/invitationApi'
import { Typography, Button } from '@astrid/components'

interface InvitationModalProps {
  info: DetailedInvitationInfo
  closeModal: () => void
}

const InvitationModal: React.FC<InvitationModalProps> = ({ info, closeModal }) => {
  const {
    className,
    grade,
    teacher: {
      profile: { firstName, lastName }
    }
  } = info
  return (
    <div className={styles.modal}>
      <div className={styles.card}>
        <div className={styles.card_info}>
          <Typography variant="h3">You have joined a class</Typography>
          <Typography variant="exerciseS">Class name: {className}</Typography>
          <Typography variant="exerciseS">Grade: {grade}</Typography>
          <Typography variant="exerciseS">
            Teacher: {firstName} {lastName}
          </Typography>
        </div>
        <Button onClick={closeModal} variant="filled" color="primary">
          Close
        </Button>
      </div>
    </div>
  )
}

export default InvitationModal
