import React, { useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

import {
  Typography,
  CircularProgressBar,
  Button,
  Rate,
  BookRate,
  Book,
  useWindowSize,
  BookContext
} from '@astrid/components'
import FlyingStars from 'components/FlyingStars/FlyingStars'
import BookButton from 'components/BookButton/BookButton'
import { ReactComponent as LevelIcon } from 'resources/graphics/icons/level.svg'
import { routes } from 'routes/RouterStackComponent'
import { getUserLevelPercent, getUserLevel } from 'utils'
import { useUpdateEffect } from 'utils/hooks'
import './Congratulations.scss'

interface ICongratulationsProps {
  name: string
  bookTitle: string
  bookTotalPoints: number
  starsFlying: boolean
  globalScore: number
  bookContext: BookContext
  displayRate?: boolean
  displayNextBook?: boolean
  nextBook?: Book
  rateBook?: (rate: BookRate) => void
}

const Congratulations: React.FC<ICongratulationsProps> = ({
  name,
  bookTitle,
  bookTotalPoints,
  starsFlying,
  globalScore,
  displayRate,
  displayNextBook,
  bookContext,
  nextBook,
  rateBook
}) => {
  const [lvlUp, setLvlUp] = useState(false)
  const history = useHistory()
  const { width } = useWindowSize()

  const userLevelPercent = useMemo(() => {
    return getUserLevelPercent(globalScore)
  }, [globalScore])

  const userLevel = useMemo(() => {
    return getUserLevel(globalScore)
  }, [globalScore])

  useUpdateEffect(() => {
    setLvlUp(true)
  }, [userLevel])

  const progressBarSize = width! > 500 ? 200 : 150
  const progressBarStroke = width! > 500 ? 30 : 20

  return (
    <div className="congratulations">
      {displayNextBook && !!nextBook && (
        <div className="congratulations__timer">
          <div onAnimationEnd={() => history.replace(`${routes.book}/${bookContext}/${nextBook._id}`)} />
        </div>
      )}
      <Typography variant="h2" component="h1">
        Great job
        {width! > 600 ? (
          <>
            {` ${name}!`}
            <br />
            You finished {bookTitle}
          </>
        ) : (
          '!'
        )}
      </Typography>
      <Typography variant="exerciseS" className="congratulations__info">
        You've earned {bookTotalPoints} stars. Keep up the good work and go for that level-up!
      </Typography>
      <div className="congratulations__progress">
        <div className="congratulations__progress-wrapper">
          <CircularProgressBar
            progressColor="#ffdc4a"
            backgroundColor="#d3ab05"
            percentage={100}
            size={progressBarSize}
            strokeWidth={progressBarStroke}
            justForShow>
            <FlyingStars startFlight={starsFlying} />
          </CircularProgressBar>
          <Typography variant="h3">
            {bookTotalPoints}/{bookTotalPoints}
          </Typography>
        </div>
        <div className="congratulations__progress-wrapper">
          <CircularProgressBar
            backgroundColor="#18acb7"
            progressColor="#6fe2dc"
            percentage={userLevelPercent}
            size={progressBarSize}
            strokeWidth={progressBarStroke}>
            <LevelIcon className="congratulations__level" />
          </CircularProgressBar>
          <Typography
            variant="h3"
            style={{ wordBreak: 'keep-all' }}
            className={lvlUp ? 'congratulations__level-up' : ''}
            onAnimationEnd={() => setLvlUp(false)}>
            Level {userLevel}
          </Typography>
        </div>
      </div>
      <div className="congratulations__footer">
        {displayRate && rateBook && (
          <Rate
            className="congratulations__rate"
            text="Rate this book:"
            onGood={() => rateBook(BookRate.GOOD)}
            onBad={() => rateBook(BookRate.BAD)}
          />
        )}
        {displayNextBook && nextBook ? (
          <BookButton book={nextBook} text="Next book" />
        ) : (
          <Button label="Continue" onClick={() => history.replace(routes.home)} variant="filled" />
        )}
      </div>
    </div>
  )
}

export default Congratulations
