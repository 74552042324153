import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import {
  Typography,
  BookCarousel,
  GA_pageview,
  GA_customDimensions,
  GA_event,
  BookContext,
  BookType,
  sortBooksByCEFR
} from '@astrid/components'
import JumpBackIn from 'components/JumpBackIn/JumpBackIn'
import LevelUpCarousel from 'components/LevelUpCarousel/LevelUpCarousel'
import InvitationModal from 'components/InvitationModal/InvitationModal'

import InvitationApi, { DetailedInvitationInfo } from 'api/invitationApi'
import { selectProfile, getUser } from 'store/services/Auth/authReducer'
import { selectAllBooks, getAllBooks } from 'store/services/Book/bookReducer'
import { selectAllBookProgress, getAllBooksProgress } from 'store/services/BookProgress/bookProgressReducer'

import './Dashboard.scss'
import { GAEventCategory, GAEventActions } from 'utils/constants'

const Dashboard: React.FC = () => {
  const [invitationInfo, setInvitationInfo] = useState<DetailedInvitationInfo | null>(null)
  const dispatch = useDispatch()
  const history = useHistory()
  const profile = useSelector(selectProfile)
  const books = useSelector(selectAllBooks)
  const booksProgress = useSelector(selectAllBookProgress)
  const recommendedBook = books.find((b) => b.recommended)

  useEffect(() => {
    GA_pageview('/dashboard')
    try {
      dispatch(getUser())
    } catch (error) {}
    dispatch(getAllBooks())
    dispatch(getAllBooksProgress())
    ;(async () => {
      const invitationId = InvitationApi.getInvitationId()
      if (invitationId) {
        try {
          const { data } = await InvitationApi.acceptInvitation(invitationId)
          setInvitationInfo(data)
        } catch (error) {
        } finally {
          InvitationApi.removeInvitationId()
        }
      }
    })()
    // eslint-disable-next-line
  }, [])

  const onBookCarouselItemClick = useCallback(
    (bookId: string, bookTitle: string, context: BookContext) => {
      GA_customDimensions(bookTitle)
      GA_event({
        category: GAEventCategory.BOOK,
        action: GAEventActions.BOOK_ENTER,
        label: 'Book clicked on carousel'
      })
      GA_customDimensions()
      history.push(`/book/${context}/${bookId}`)
    },
    [history]
  )

  const readingBooks = useMemo(() => {
    return sortBooksByCEFR(books.filter((book) => book.bookType !== BookType.Listening))
  }, [books])

  const listeningBooks = useMemo(() => {
    return sortBooksByCEFR(books.filter((book) => book.bookType !== BookType.Reading))
  }, [books])

  return (
    <div className="dashboard">
      {!!invitationInfo && <InvitationModal info={invitationInfo} closeModal={() => setInvitationInfo(null)} />}
      {recommendedBook && <JumpBackIn book={recommendedBook} name={profile?.firstName} />}
      <div className="dashboard__book-carousel">
        <Typography variant="h3" component="h1">
          Reading
        </Typography>
        <BookCarousel
          displayTypeIcon
          books={readingBooks}
          booksProgress={booksProgress}
          onItemClick={(bId, bT) => onBookCarouselItemClick(bId, bT, BookContext.reading)}
        />
      </div>
      <div className="dashboard__book-carousel">
        <Typography variant="h3" component="h1">
          Listening
        </Typography>
        <BookCarousel
          displayTypeIcon
          books={listeningBooks}
          booksProgress={booksProgress}
          onItemClick={(bId, bT) => onBookCarouselItemClick(bId, bT, BookContext.listening)}
        />
      </div>
      <div className="dashboard__level-up">
        <Typography variant="h3" component="h1">
          Level up!
        </Typography>
        <LevelUpCarousel />
      </div>
    </div>
  )
}

export default Dashboard
