import React from 'react'
import { Provider as ReduxProvider } from 'react-redux'
import 'matchmedia-polyfill'

import RouterStackComponent from 'routes/RouterStackComponent'
import { SoundEffectsProvider, AlertProvider } from '@astrid/components'
import initStore from './store/store'

import './App.scss'
import AchievementHandler from 'components/AchievementHandler/AchievementHandler'

export default function App() {
  const store = initStore()
  return (
    <ReduxProvider store={store}>
      <SoundEffectsProvider>
        <AlertProvider>
          <div className="app">
            <RouterStackComponent />
            <AchievementHandler />
          </div>
          <div id="draggable" />
        </AlertProvider>
      </SoundEffectsProvider>
    </ReduxProvider>
  )
}
