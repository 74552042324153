import React, { useState } from 'react'

import { ReactComponent as CloseIcon } from '../../graphics/icons/close.svg'

import './AvatarUpload.scss'

interface IProps {
  file: File | string | null | undefined
  className?: string
  style?: React.CSSProperties
  removeImage: () => void
  uploadImage: (image: File) => void
}

const AVATAR_SIZE_LIMIT_MB = 5 // [MB]

const AvatarUpload: React.FC<IProps> = ({ file, uploadImage, removeImage, style, className }) => {
  const [error, setError] = useState<'size' | 'format' | false>(false)

  const checkImageAndUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i
    const image = e.target.files?.item(0)
    const fileName = e.target.value
    if (image) {
      if (image.size > AVATAR_SIZE_LIMIT_MB * 1000000) {
        setError('size')
        return
      }
      if (!allowedExtensions.exec(fileName)) {
        setError('format')
        return
      }
      setError(false)
      uploadImage(image)
    }
  }

  return file ? (
    <div className={`avatar-display ${className ? className : ''}`} style={style}>
      <div>
        <img srcSet={typeof file === 'string' ? file : URL.createObjectURL(file)} alt="Avatar" />
        <div className="avatar-display__overlay" onClick={removeImage}>
          <CloseIcon />
        </div>
      </div>
    </div>
  ) : (
    <div className={`avatar-upload ${className ? className : ''}`} style={style}>
      <input type="file" name="avatar" id="avatarUpload" accept=".jpg,.jpeg,.png,.gif" onChange={checkImageAndUpload} />
      <label htmlFor="avatarUpload">+ Add avatar</label>
      {!!error && (
        <span>
          {error === 'size'
            ? `File too large. Limit is ${AVATAR_SIZE_LIMIT_MB}MB.`
            : 'Please use a jpg, gif, or png image for your avatar.'}
        </span>
      )}
    </div>
  )
}

export default AvatarUpload
