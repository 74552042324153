import React, { createRef, useCallback, useEffect, useMemo } from 'react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider, { Settings } from 'react-slick'

import { ReactComponent as Arrow } from '../../graphics/icons/chevron-right.svg'
import { SkeletonLoader } from '../../index'
import BookCarouselItem from './BookCarouselItem/BookCarouselItem'
import { Book, BookProgress, BookType } from '../../types'

import './BookCarousel.scss'

const loadingItems = () => {
  const items: JSX.Element[] = []
  for (let i = 0; i < 4; i++) {
    items.push(
      <SkeletonLoader key={`loading${i}`} className="book-carousel__loading" display>
        <div />
        <div />
      </SkeletonLoader>
    )
  }
  return items
}

interface BookCarouselProps {
  books: Book[]
  onItemClick: (bookId: string, bookTitle: string, bookType: BookType) => void
  booksProgress?: BookProgress[]
  loading?: boolean
  displayTypeIcon?: boolean
}

const BookCarousel: React.FC<BookCarouselProps> = ({ books, onItemClick, loading, booksProgress, displayTypeIcon }) => {
  const slider = createRef<Slider>()

  const settings: Settings = {
    infinite: books.length > 4,
    slidesToShow: 4,
    slidesToScroll: 4,
    speed: 500,
    nextArrow: undefined,
    prevArrow: undefined,
    responsive: [
      {
        breakpoint: 1366,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

  const mapBooksToCarouselItems = useCallback(
    (books_: Book[]) => {
      return books_.map((book) => {
        const progress = booksProgress ? booksProgress.find((prog) => prog.bookId === book._id) : undefined
        return (
          <BookCarouselItem
            key={book._id}
            book={book}
            progress={progress}
            onClick={onItemClick}
            displayTypeIcon={displayTypeIcon}
          />
        )
      })
    },
    [booksProgress]
  )

  const booksToRender = useMemo(() => {
    return mapBooksToCarouselItems(books)
  }, [books, booksProgress])

  useEffect(() => {
    if (booksToRender.length > 0 && slider.current) {
      const recommendedBookIndex = books.findIndex((b) => b.recommended)
      if (recommendedBookIndex !== -1) {
        slider.current.slickGoTo(recommendedBookIndex, true)
      }
    }
  }, [booksToRender])

  return (
    <div className="book-carousel" data-testid="BookCarousel">
      <button onClick={() => slider.current?.slickPrev()} className="left">
        <Arrow />
      </button>
      <Slider {...settings} ref={slider} className="book-carousel__fixer">
        {loading ? loadingItems() : booksToRender}
      </Slider>
      <button onClick={() => slider.current?.slickNext()} className="right">
        <Arrow />
      </button>
    </div>
  )
}

export default BookCarousel
