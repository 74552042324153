import React, { useCallback, useContext, useEffect } from 'react'
import * as Yup from 'yup'
import { Formik, FormikHelpers } from 'formik'
import { Typography, TextInput, Button, AlertContext, GA_event, GA_pageview, ValidationRules } from '@astrid/components'
import { NavLink } from 'react-router-dom'
import { AuthCredentials } from 'store/services/Auth/types'
import { register } from 'store/services/Auth/authReducer'
import AuthWrapper from 'components/AuthWrapper/AuthWrapper'
import { routes } from 'routes/RouterStackComponent'
import { useDispatch } from 'react-redux'
import { printApiMessage } from 'api/apiMessages'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import { GAEventCategory, GAEventActions } from 'utils/constants'

const validationSchema = Yup.object().shape({
  username: ValidationRules.email,
  password: ValidationRules.password,
  password2: Yup.string()
    .oneOf([Yup.ref('password'), ''], "Passwords don't match")
    .required('Required'),
  termsChecked: Yup.boolean().oneOf([true])
})

interface FormFields extends AuthCredentials {
  password2: string
  termsChecked: boolean
}

const Register: React.FC = () => {
  const dispatch = useDispatch()
  const { showAlert } = useContext(AlertContext)

  const handleSubmit = useCallback(
    async ({ username, password }: FormFields, { setSubmitting }: FormikHelpers<FormFields>) => {
      try {
        await dispatch(register({ username, password }))
        GA_event({
          category: GAEventCategory.USER,
          action: GAEventActions.REGISTER,
          label: 'Register'
        })
      } catch ({ message }) {
        setSubmitting(false)
        showAlert(printApiMessage(message))
      }
    },
    // eslint-disable-next-line
    []
  )

  useEffect(() => {
    GA_pageview(window.location.pathname)
  }, [])

  return (
    <AuthWrapper>
      <Typography variant="h1" style={{ margin: 0 }}>
        Create an account
      </Typography>
      <Typography variant="body">sign up with your email</Typography>

      <Formik
        initialValues={{ username: '', password: '', password2: '', termsChecked: false }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}>
        {({ values, touched, errors, handleChange, setFieldTouched, handleSubmit, isSubmitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              <TextInput
                name="username"
                label="Your Email"
                value={values.username}
                onChange={handleChange('username')}
                error={touched.username && !!errors.username}
                helperText={(touched.username && errors.username) || ''}
                onBlur={() => setFieldTouched('username')}
              />
              <TextInput
                name="password"
                label="Enter Password"
                type="password"
                value={values.password}
                onChange={handleChange('password')}
                error={touched.password && !!errors.password}
                helperText={(touched.password && errors.password) || ''}
                onBlur={() => setFieldTouched('password')}
              />
              <TextInput
                name="password2"
                label="Confirm Password"
                type="password"
                value={values.password2}
                onChange={handleChange('password2')}
                error={touched.password2 && !!errors.password2}
                helperText={(touched.password2 && errors.password2) || ''}
                onBlur={() => setFieldTouched('password2')}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    data-testid="terms"
                    checked={values.termsChecked}
                    onChange={handleChange('termsChecked')}
                    name="termsChecked"
                    color="primary"
                    value={values.termsChecked}
                    style={{ color: !!errors.termsChecked ? 'red' : undefined }}
                  />
                }
                label={
                  <span>
                    I agree to{' '}
                    <a target="_blank" href="./terms_and_conditions.html">
                      terms & conditions
                    </a>
                  </span>
                }
              />
              <Button type="submit" disabled={isSubmitting}>
                Create
              </Button>
            </form>
          )
        }}
      </Formik>
      <Typography variant="body" color="secondary">
        Already have an account? <NavLink to={routes.home}>Log in</NavLink>
      </Typography>
    </AuthWrapper>
  )
}

export default Register
