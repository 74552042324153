import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  ArrowRight,
  Book,
  Button,
  CharacterMood,
  DifficultWords,
  LottieCharacter,
  Typography
} from '@astrid/components'
import { sanaGetScore } from 'api/sanaApi'
import { selectUserCountry } from 'store/services/Auth/authReducer'
import { IBookProgress } from 'store/services/BookProgress/types'

import styles from './DifficultWordsPractise.module.scss'
import { setActiveExerciseIndex, setCurrentBookExercisesAmount } from 'store/services/Exercise/exerciseReducer'

interface DifficultWordsPractiseProps {
  bookProgress: IBookProgress
  book: Book
  onFinish: () => void
}

const DifficultWordsPractise: React.FC<DifficultWordsPractiseProps> = ({ bookProgress, book, onFinish }) => {
  const dispatch = useDispatch()
  const country = useSelector(selectUserCountry)
  const [start, setStart] = useState(false)
  const [characterMood, setCharacterMood] = useState<CharacterMood>('correct')

  useEffect(() => {
    dispatch(setActiveExerciseIndex(bookProgress.index))
    dispatch(setCurrentBookExercisesAmount(book.exercises.length))
  }, [dispatch, bookProgress.index, book.exercises.length])

  if (start) {
    return (
      <DifficultWords
        country={country}
        difficultWords={bookProgress.difficultWords}
        onFinish={onFinish}
        getSanaScore={sanaGetScore}
      />
    )
  } else {
    return (
      <div className={styles.container}>
        <Typography variant="exerciseL">Let's try the hardest words again!</Typography>
        <LottieCharacter
          mood={characterMood}
          changeCharacterMood={setCharacterMood}
          characterName="Abbe"
          neverBored
          className={styles.character}
        />
        <Button onClick={() => setStart(true)} className={styles.continue}>
          Continue <ArrowRight />
        </Button>
      </div>
    )
  }
}

export default DifficultWordsPractise
