import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import App from './App'
import * as serviceWorker from './serviceWorker'
import * as Sentry from '@sentry/react'
import { GA_init, initHotjar } from '@astrid/components'

if (process.env.NODE_ENV !== 'development' && process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.NODE_ENV
  })
}

GA_init(process.env.REACT_APP_GA_ID)

if (
  process.env.NODE_ENV !== 'development' &&
  process.env.REACT_APP_HOTJAR_ID &&
  typeof process.env.REACT_APP_HOTJAR_ID === 'string'
) {
  initHotjar(process.env.REACT_APP_HOTJAR_ID)
}

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
