import React, { useState, useEffect } from 'react'
import Congratulations from './Congratulations/Congratulations'
import { useSelector, useDispatch } from 'react-redux'
import { selectProfile, updateGlobalScore } from 'store/services/Auth/authReducer'
import { BookContext } from '@astrid/components'

const OnboardingFinished: React.FC = () => {
  const [starsFlying, setStarsFlying] = useState(false)
  const profile = useSelector(selectProfile)
  const dispatch = useDispatch()

  const onDidMount = () => {
    if (profile!.globalScore! < 1000) {
      setTimeout(() => {
        dispatch(updateGlobalScore(1000))
      }, 2000)
      setTimeout(() => {
        setStarsFlying(true)
      }, 2250)
    }
  }

  useEffect(onDidMount, [])

  return (
    <Congratulations
      name={profile!.firstName}
      bookContext={BookContext.reading}
      bookTitle="Onboarding Book"
      bookTotalPoints={1000}
      globalScore={profile!.globalScore!}
      starsFlying={starsFlying}
    />
  )
}

export default OnboardingFinished
