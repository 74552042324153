import React from 'react'

import './SkeletonLoader.scss'

interface SkeletonLoaderProps {
  display: boolean
  className?: string
  style?: React.CSSProperties
}

const SkeletonLoader: React.FC<SkeletonLoaderProps> = ({ display, className, style, children }) => {
  return (
    <div
      className={`${className ? className : ''} skeleton-loader`}
      style={{ display: display ? 'block' : 'none', ...style }}>
      {children}
    </div>
  )
}

export default SkeletonLoader
